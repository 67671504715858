@font-face {
  font-family: 'BentonSans Regular';
  src: local('BentonSans Regular'), url(./fonts/BentonSans-Regular.woff) format('woff');
}

@font-face {
  font-family: "dls-icons-2.16.0";
  font-weight: normal;
  font-display: block;
  src: local('dls-icons-2.16.0'), url(./fonts/dls-icons.woff) format('woff');
}

html {
  height: 100%;
}

body {
  margin: 0;
  background-color: #f7f8f9;
}

.column {
  flex: 50%;
}

.column-vertical-center {
  flex: 50%;
  justify-content: center;
  flex-direction: row;
}

.column-small {
  flex: 14%;
}

.column-divider {
  width: 2px;
}

.row {
  display: flex;
  width: 100%;
}

div[role="button"] {
  cursor: pointer;
}

span[role="button"] {
  cursor: pointer;
}

#okta-sign-in .auth-container .button-primary {
  background-color: #006fcf
}

#okta-sign-in .auth-content {
  background: white
}

#okta-sign-in .auth-container {
  background-color: white
}

#okta-sign-in .okta-sign-in-header {
  background-image: linear-gradient(278deg, rgba(0, 111, 207, 0.7) 82%, rgba(0, 58, 136, 0.88) 58%, rgba(0, 27, 95, 0.99) 37%, #00175a 29%, #00175a 23%);
  background-image: -moz-linear-gradient(top, rgba(0, 23, 90, 1) 23%, rgba(0, 23, 90, 0.97) 29%, rgba(0, 27, 95, 0.93) 37%, rgba(0, 58, 136, 0.82) 58%, rgba(0, 111, 207, 0.7) 82%);
  /* FF3.6-15 */
  background-image: -webkit-linear-gradient(top, rgba(0, 23, 90, 1) 23%, rgba(0, 23, 90, 0.97) 29%, rgba(0, 27, 95, 0.93) 37%, rgba(0, 58, 136, 0.82) 58%, rgba(0, 111, 207, 0.7) 82%);
  /* Chrome10-25,Safari5.1-6 */
  background-image: linear-gradient(82deg, rgba(0, 23, 90, 1) 23%, rgba(0, 23, 90, 0.97) 29%, rgba(0, 27, 95, 0.93) 37%, rgba(0, 58, 136, 0.82) 58%, rgba(0, 111, 207, 0.7) 82%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00175a', endColorstr='#b3006fcf', GradientType=0);
  /* IE6-9 */
}

.spaced-row {
  display: flex;
  margin-top: 27px;
}

.spaced-row-mobile {
  /* display: flex; */
  margin-top: 27px;
}

.responsive-row {
  display: flex;
  flex-direction: row;
}

.Icon-Container {
  display: flex;
  justify-content: flex-end;
  width: 160px;
  margin-right: 23px;
}

.Image-Container {
  /* margin-left: 10px; */
  height: 32px;
  margin-top: 5px;
}

img.centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.Header-Background {
  /* width: 1140px; */
  min-height: 66px;
  width: 100%;
  /* padding-left: 390px;
  padding-right: 390px; */
  background-color: #ffffff;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Header-Container {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}

.Fill-2 {
  width: 39px;
  height: 39px;
  background-color: #006fcf;
  float: left;
}

.Tabs-Background {
  /* width: 1920px; */
  width: 100%;
  height: 64px;
  /* box-shadow: 4px 0 12px 0 rgba(0, 0, 0, 0.08); */
  background-color: #ffffff;
  display: flex;
  justify-content: center;
}

.Header-Tab {
  width: 200px;
  height: 64px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
}

.Separation-Line {
  /* width: 1920px; */
  width: 100%;
  height: 1px;
  background-color: #a7a8aa;
}

.Page-Background {
  /* width: 1540px; */
  width: 100%;
  height: 1023px;
  padding-top: 32px;
  /* padding-left: 380px; */
  background: url(https://cdn.amexgbt.com/brand19/images/hero_image_home_background_1920px.png) no-repeat;
  background-size: cover;
  /*background-color: #f7f8f9;*/
  /* padding-left: 2.5%;
  padding-right: 2.5%; */
}

.Trips-Background {
  max-width: 374px;
  min-height: 100%;
  height: auto;
  background-color: #ffffff;
  display: block;
}

.Scroll-Container {
  height: 87%;
  overflow: scroll;
}

.Background-Mask {
  max-width: 1159px;
  /* width: 90%; */
  height: 1013px;
  border-radius: 16px;
  box-shadow: 0 10px 80px 10px rgba(0, 0, 0, 0.08);
  background-color: #f7f8f9;
  overflow: hidden;
  margin: 0 auto;
}

.Banner-Gradient {
  max-width: 780px;
  height: 240px;
  margin-bottom: -79px;
  background-color: #006FCF;
  /* background-image: linear-gradient(278deg, rgba(0, 111, 207, 0.7) 82%, rgba(0, 58, 136, 0.88) 58%, rgba(0, 27, 95, 0.99) 37%, #00175a 29%, #00175a 23%);
  background-image: -moz-linear-gradient(top, rgba(0, 23, 90, 1) 23%, rgba(0, 23, 90, 0.97) 29%, rgba(0, 27, 95, 0.93) 37%, rgba(0, 58, 136, 0.82) 58%, rgba(0, 111, 207, 0.7) 82%); */
  /* FF3.6-15 */
  /* background-image: -webkit-linear-gradient(top, rgba(0, 23, 90, 1) 23%, rgba(0, 23, 90, 0.97) 29%, rgba(0, 27, 95, 0.93) 37%, rgba(0, 58, 136, 0.82) 58%, rgba(0, 111, 207, 0.7) 82%); */
  /* Chrome10-25,Safari5.1-6 */
  /* background-image: linear-gradient(82deg, rgba(0, 23, 90, 1) 23%, rgba(0, 23, 90, 0.97) 29%, rgba(0, 27, 95, 0.93) 37%, rgba(0, 58, 136, 0.82) 58%, rgba(0, 111, 207, 0.7) 82%); */
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  /* filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00175a', endColorstr='#b3006fcf', GradientType=0); */
  /* IE6-9 */

}

.Documents-List-Background {
  width: 721px;
  height: 822px;
  margin-left: 32px;
  border-radius: 8px;
  background-color: #ffffff;
}

.Globe {
  height: 45px;
  width: 70px;
  float: left;
}

.Account {
  height: 45px;
  width: 47px;
  margin-left: 35px;
  float: right;
}

.English-US {
  width: 70px;
  height: 17px;
  font-family: "BentonSans Regular";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #006fcf;
  margin-top: 4px;
}

.Account-Text {
  width: 47px;
  height: 17px;
  font-family: "BentonSans Regular";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #006fcf;
  margin-top: 4px;
}

.tabDivider {
  position: relative;
  width: 24px;
  height: 1px;
  top: 50%;
  transform: rotate(-90deg);
  border-radius: 1px;
  background-color: #a7a8aa;
}

.Link-line-1 {
  width: 100%;
  height: 47px;
  padding-bottom: 9px;
  font-family: "BentonSans Regular";
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-top: 18px;
  color: #00175a;
  display: flex;
  justify-content: center;
  align-self: center;
  /* align-items: center; */
}

.Style-Icons-Universal-Invoice-invoice_active {
  width: 30px;
  height: 30px;
  margin-right: 16px;
  margin-bottom: 60px;
  object-fit: contain;
  justify-content: center;
  align-self: center;
  cursor: pointer;
}

.Style-Icons-Universal-Home-home_active {
  width: 30px;
  height: 30px;
  margin-right: 16px;
  /* margin-bottom: 60px; */
  object-fit: contain;
  justify-content: center;
  align-self: center;
  cursor: pointer;
}

.Line {
  width: 200px;
  height: 4px;
  border-radius: 2px;
  background-color: #00175a;
  align-self: flex-end;
  display: flex;
  justify-content: center;
}

.active {
  width: 200px;
  height: 4px;
  border-radius: 2px;
  background-color: #00175a;
  align-self: flex-end;
  display: flex;
  justify-content: center;
}

/* .Vertical-Center {

} */

.Documents-Heading {
  width: 374px;
  height: 30px;
  font-family: "BentonSans Regular";
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #00175a;
  margin-top: 64px;
  margin-left: 32px;
  margin-bottom: 16px;
  display: block;
}

.Sidebar-Tabs {
  width: 374px;
  height: 62px;
  display: flex;
}

.Sidebar-Tab {
  text-align: center;
  height: 62px;
}

.-line {
  width: 187px;
  height: 22px;
  font-family: "BentonSans Regular";
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: #00175a;
  margin-top: 16px;
}

.Bar {
  width: 187px;
  height: 4px;
  background-color: #00175a;
}

.Sidebar-Tab-Inactive {
  width: 185px;
  height: 60px;
  border: solid 1px #ecedee;
  background-color: #f7f8f9;
}

.Sidebar-Tab-Text-Inactive {
  width: 100%;
  height: 22px;
  font-family: "BentonSans Regular";
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: #006fcf;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
}

.Card-Container {
  width: 311px;
  height: 123px;
  margin-left: 32px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}

.Card-Info {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  margin-left: -11px;
}

.Card-Separation-Line {
  width: 374px;
  height: 1px;
  background-color: #ecedee;
  align-self: flex-end;
}

.Arrow {
  width: 0px;
  height: 0px;
  margin-right: 16px;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid #006fcf;
  display: inline-block;
  align-self: center;
  justify-self: flex-end;
}

.Arrow-Selected {
  width: 0px;
  height: 0px;
  margin-right: 16px;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid #00175a;
  display: inline-block;
  align-self: center;
  justify-self: flex-end;
}

.Trip-name {
  width: 333px;
  /* height: 30px; */
  font-family: "BentonSans Regular";
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #006fcf;
}

.Trip-Name-Selected {
  width: 333px;
  /* height: 30px; */
  font-family: "BentonSans Regular";
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #00175a;
}

.Departure-Date {
  width: 300px;
  height: 24px;
  font-family: "BentonSans Regular";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #333333;
}

.Departure-Date-Selected {
  width: 300px;
  height: 24px;
  font-family: "BentonSans Regular";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #00175a;
}

.Traveler-Name {
  display: flex;
  width: 306px;
  margin-top: 4px;
  margin-bottom: -3px;
  font-family: "BentonSans Regular";
  line-height: 22px;
  color: #333333;
}
.Multi-User-Name-Container {
  display: flex;
}

.Multi-User-Name {
  width: 261px;
  font-family: "BentonSans Regular";
  padding-left: 24px;
}

.User-Name-Icon {
  height: 24px;
}

.User-Name {
  width: 250px;
  font-family: "BentonSans Regular";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #53565a;
  /* display: flex; */
  /* justify-content: flex-start; */
  align-self: center;
  align-items: center;
}

.Document-View-Title {
  width: 90%;
  height: 40px;
  font-family: "BentonSans Regular";
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #ffffff;
  margin-top: 35px;
  margin-left: -5px;
}

.support-header {
  display: flex;
  justify-content: space-around;
  font-family: "BentonSans Regular";
  font-size: 0.83em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
  position: absolute;
  top: 1px;
  right: 22px;
  width: 43%;
  cursor: pointer;
}

.support-text-hidden {
  display: none;
}

.integration-text {
  font-family: "BentonSans Regular";
  font-size: 0.85em;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
  border: 3px solid white;
  padding: 5px;
  margin-top: 8px;
  margin-right: 5px;
  width: 95%;
}

.integration-text-hidden {
  display: none;
}

.opentextURL {
  font-family: "BentonSans Regular";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
  font-size: 0.95em;
  position: relative;
  top: 3px;
  cursor: pointer;
  text-decoration: underline;
}

.opentextURL2 {
  font-family: "BentonSans Regular";
  font-size: 0.95em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
  font-style: normal;
  position: relative;
  top: -0.1px;
}

.Description {
  width: 573px;
  height: 24px;
  font-family: "BentonSans Regular";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
}

.Title-Container {
  padding-top: 60px;
  padding-left: 40px;
  position: relative;
}

.Invoices-Invoice-Detail-Header {
  width: 90%;
  min-height: 195px;
  padding-top: 32px;
  padding-left: 32px;
}

.GBT-Booking-Ref-Title {
  height: 30px;
  font-family: "BentonSans Regular";
  font-size: 19px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #00175a;
}

.Title-Trip-Date {
  height: 24px;
  font-family: "BentonSans Regular";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #333333;
}

.Name {
  width: 274px;
  font-family: "BentonSans Regular";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #333333;
}

.Style-Icons-Glyphs-Icons-General-Account-Account-Default {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 8px;
}

.Email-Detail-Label {
  width: 137px;
  height: 18px;
  font-family: "BentonSans Regular";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #53565a;
  margin-bottom: 8px;
}

.Email-List-Item {
  width: 137px;
  height: 18px;
  font-family: "BentonSans Regular";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #53565a;
  margin-bottom: 10px;
  margin-top: 10px;
}

.Element-Divider-1px {
  width: 100%;
  height: 1px;
  background-color: #c8c9c7;
}

.Element-Divider-Short-1px {
  width: 90%;
  height: 1px;
  background-color: #ecedee;
  margin-right: auto;
  margin-left: auto;
}

.Checkbox {
  width: 22px;
  height: 22px;
  border-radius: 3px;
  border: solid 1px #c8c9c7;
  background-color: #ffffff;
}

.Title-Controls {
  margin-top: 27px;
  display: flex;
  align-content: center;
  align-items: center;
  height: 53px;
}

.Pick-Up {
  width: 200px;
  height: 30px;
  font-family: "BentonSans Regular";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #333333;
  vertical-align: middle;
  display: flex;
  align-content: center;
  align-items: center;
}

.Export-Controls-Background {
  width: 273px;
  height: 53px;
  border-radius: 8px;
  background-color: #f7f8f9;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
  margin-top: 20px;
  float: right;
}

.View-Map-Link {
  width: 65px;
  height: 21px;
  font-family: "BentonSans Regular";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #97999B;
  margin-right: auto;
  margin-left: auto;
  vertical-align: middle;
}

.View-Map-Link-Active {
  width: 65px;
  height: 21px;
  font-family: "BentonSans Regular";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #006fcf;
  margin-right: auto;
  margin-left: auto;
  vertical-align: middle;
}

.Left-Space {
  margin-left: 29px;
}

.Small-Left-Space {
  margin-left: 10px;
}

img.Centered {
  margin-top: auto;
  margin-bottom: auto;
}

.column.Centered {
  margin-top: auto;
  margin-bottom: auto;
}

.Document-Card {
  width: 680px;
  height: 124px;
  padding-left: 32px;
  padding-top: 24px;
}

.Document-Card-Label {
  width: 74px;
  height: 18px;
  font-family: "BentonSans Regular";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #53565a;
}

.Invoice {
  width: 155px;
  height: 21px;
  font-family: "BentonSans Regular";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #006fcf;
  cursor: pointer;
}

.Document-Card-Details {
  width: 68px;
  height: 24px;
  font-family: "BentonSans Regular";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #333333;
}

.Expand-Emails {
  width: 51px;
  height: 18px;
  font-family: "BentonSans Regular";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #006fcf;
}

.Total-Label {
  width: 46px;
  height: 18px;
  font-family: "BentonSans Regular";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #53565a;
}

.Green-Total {
  width: 80px;
  height: 24px;
  font-family: "BentonSans Regular";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #008767;
  margin-left: 36px;
}

.Green-Total-Mobile {
  width: 80px;
  height: 24px;
  font-family: "BentonSans Regular";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #008767;
}

.Card-Container-Active {
  width: 300px;
  height: 123px;
  margin-left: 34px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  background-color: #f7f8f9;
}

.Card-Container-Title-Active {
  width: 255px;
  height: 30px;
  font-family: "BentonSans Regular";
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #006fcf;
}

.Card-Container-Date-Active {
  width: 225px;
  height: 24px;
  font-family: "BentonSans Regular";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #333333;
}

.Card-Container-Name-Active {
  width: 70px;
  height: 18px;
  font-family: "BentonSans Regular";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #53565a;
}

.tooltipContainer {
  position: relative;
}

.Tooltip {
  width: 273px;
  height: 60px;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  border: solid 1px #c8c9c7;
  background-color: #ffffff;
  position: absolute;
  float: right;
  /* top: 460px;
  left: 1181px; */
  right: -1px;
  bottom: -35px;
  padding: 10px;
}

.Tooltip::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.Remaining-Trips {
  width: 150px;
  height: 26px;
  border-radius: 3px;
  background-color: #ecedee;
  align-content: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  justify-items: center;
  align-items: center;
  margin-bottom: 16px;
}

.Remaining-Trips-Text {
  font-family: "BentonSans Regular";
  font-size: 12px;
  height: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 26px;
  letter-spacing: normal;
  color: #53565a;
  align-self: center;
  justify-self: center;
  margin-top: 0;
  margin-bottom: 0;

}

.View-More-Card {
  justify-content: center;
  align-content: center;
  width: 250px;
  padding: 0 20px;
}

.View-More-Button {
  width: 100%;
  height: 40px;
  border: solid 1px #006fcf;
  background-color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 40px;
  letter-spacing: normal;
  color: #006fcf;
  text-align: center;
}

.Loading-Card-Text {
  width: 172px;
  height: 30px;
  font-family: "BentonSans Regular";
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #006fcf;
}

.Loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: transparent;
  color: #006fcf;
  font-size: 20px;
  font-family: "BentonSans Regular";
}

.scrollable {
  height: 78%;
  overflow: scroll;
}

@media only screen and (min-width: 851px) {
  .Mobile-Only {
    display: none;
  }
}

@media only screen and (min-width: 851px) and (max-width: 1050px) {
  .Header-Background {
    width: 960px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .Tabs-Background {
    width: 1024px;
  }

  .Page-Background {
    width: 960px;
    height: 100%;
    padding-left: 32px;
    padding-right: 32px;
  }

  .Background-Mask {
    width: 100%;
  }

  .Separation-Line {
    width: 1024px;
  }

  .Documents-List-Background {
    width: 616px;
    margin-left: 16px;
  }

  .Title-Container {
    padding-left: 32px;
  }

  .Trips-Background {
    width: 312px;
  }

  .Trip-name {
    width: 225px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.88;
    color: #00175a;
  }

  .Departure-Date {
    width: 250px;
    font-size: 14px;
    color: #00175a;
  }

  .Card-Container {
    width: 292px;
    margin-left: 20px;
  }

  .Sidebar-Tabs {
    width: 100%;
  }

  .Sidebar-Tab {
    width: 156px;
  }

  .Sidebar-Tab-Inactive {
    width: 156px;
  }

  .Sidebar-Tab-Text-Inactive {
    font-size: 13px;
  }

  .-line {
    width: 100%;
    font-size: 13px;
  }

  .Element-Divider-1px {
    width: 100%;
  }

  .Invoices-Invoice-Detail-Header {
    width: 552px;
  }

  .Mobile-Only {
    display: none;
  }
}

@media only screen and (min-width: 100px) and (max-width: 850px) {

  html {
    box-sizing: border-box;
  }

  body {
    background-color: #f7f8f9;
  }

  .Documents-Heading {
    width: auto;
  }

  .Desktop-Only {
    display: none;
  }

  .Mobile-Header {
    width: auto;
    height: 56px;
    background-color: #ffffff;
  }

  .Trips-Background {
    width: auto;
    min-height: 100%;
    height: auto;
    border-radius: 16px;
    background-color: #ffffff;
  }

  .Background-Mask {
    width: auto;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    background-color: #f7f8f9;
  }

  .Page-Background {
    width: auto;
    max-width: 374px;
    height: 100%;
    padding: 18px 8px 8px 8px;
    background-color: #f7f8f9;
    margin-left: auto;
    margin-right: auto;
  }

  .Header-Background {
    width: auto;
    height: 21px;
    padding: 17px 17px 18px;
    margin-right: 0;
    margin-left: 0;
    background-color: #ffffff;
  }

  .Scroll-Container {
    height: 100%;
    overflow: visible;
  }

  .Account {
    margin-left: 0px;
    width: 30px;
    float: right;
  }

  .Globe {
    width: 30px;
    float: left;
  }

  .Card-Container {
    width: auto;
    margin-left: 20px;
  }


  .Icon-Container {
    width: 70px;
  }

  .Banner-Gradient {
    width: auto;
    height: 283px;
    margin-bottom: -91px;
    border-radius: 0px;
    background-color: #006FCF;
    /* background-image: linear-gradient(278deg, rgba(0, 111, 207, 0.7) 82%, rgba(0, 58, 136, 0.88) 58%, rgba(0, 27, 95, 0.99) 37%, #00175a 29%, #00175a 23%);
    background-image: -moz-linear-gradient(top, rgba(0, 23, 90, 1) 23%, rgba(0, 23, 90, 0.97) 29%, rgba(0, 27, 95, 0.93) 37%, rgba(0, 58, 136, 0.82) 58%, rgba(0, 111, 207, 0.7) 82%); */
    /* FF3.6-15 */
    /* background-image: -webkit-linear-gradient(top, rgba(0, 23, 90, 1) 23%, rgba(0, 23, 90, 0.97) 29%, rgba(0, 27, 95, 0.93) 37%, rgba(0, 58, 136, 0.82) 58%, rgba(0, 111, 207, 0.7) 82%); */
    /* Chrome10-25,Safari5.1-6 */
    /* background-image: linear-gradient(82deg, rgba(0, 23, 90, 1) 23%, rgba(0, 23, 90, 0.97) 29%, rgba(0, 27, 95, 0.93) 37%, rgba(0, 58, 136, 0.82) 58%, rgba(0, 111, 207, 0.7) 82%); */
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    /* filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00175a', endColorstr='#b3006fcf', GradientType=0); */
    /* IE6-9 */
  }

  .Title-Container {
    padding-left: 0px;
    margin-left: auto;
    margin-right: auto;
    max-width: 374px;
    min-width: 200px;
    width: auto;
  }

  .Description {
    max-width: 400px;
    min-width: 350px;
    width: auto;
    height: 48px;
    font-family: "BentonSans Regular";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ffffff;
  }

  .Documents-List-Background {
    max-width: 374px;
    height: 1463px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 8px;
    background-color: #ffffff;
    display: block;
    overflow: hidden;
  }

  .Invoices-Invoice-Detail-Header {
    display: inline-block;
    width: 342px;
    min-height: 221px;
    padding-top: 32px;
    padding-left: 32px;
  }

  .responsive-row {
    display: flex;
    flex-direction: column;
  }

  .responsive-row .column {
    margin-bottom: 16px;
  }

  .Export-Controls-Background {
    float: none;
    width: 316px;
    margin-top: 0px;
  }

  .Traveler-Name {
    overflow: hidden;
  }

  .Title-Controls {
    margin-top: 0px;
  }

  .Name {
    width: 250px;
    font-size: 12px;
  }

  .MobileNav {
    width: 374px;
    height: 57px;
    border-radius: 8px;
    background-color: #ffffff;
    margin-bottom: 8px;
    margin-left: auto;
    margin-right: auto;
    justify-self: center;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    text-align: center;
  }

  .Mobile-Nav-Text {
    height: 100%;
    font-family: "BentonSans Regular";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    margin-top: auto;
    margin-bottom: auto;
    letter-spacing: normal;
    color: #333333;
    justify-self: center;
    align-self: center;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .Mobile-Nav-Divider {
    border-left: 1px solid #ecedee;
    width: 1px;
    height: 57px;
  }

  .column-medium {
    flex: 33%;
  }

  .Document-Card {
    height: 269px;
    min-height: 124px;
    width: 57%;
  }

  .Document-Card-Label {
    width: 100%;
    margin-bottom: 9px;
  }

  .Document-Card-Details {
    width: 100%;
    margin-bottom: 24px;
  }

  .Invoice {
    margin-bottom: 24px;
  }

  .Element-Divider-Short-1px {
    width: 80%;
  }

  .Expand-Email-Container {
    padding-right: 34px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .Expand-Emails {
    width: 61px;
    height: 21px;
    font-family: "BentonSans Regular";
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #006fcf;
  }

  .Tabs-Background {
    width: 414px;
  }

  .No-Mobile {
    display: none;
  }
}